<template>
  <div class="banner">
    <div class="topbanner-container home">
      <div class="swiper-wrapper">
        <div class="swiper-slide">
          <div class="banner01">
            <div class="text">
              <div class="hi">Hi !</div>
              <div class="title">
                Help us collect data for research <br />in neuroscience of
                decision making !
              </div>
              <div class="desc">
                You can earn points by reading articles and answering questions
                below them. In the future <br />you will be able to convert
                points to various rewards.
              </div>
              <div class="btn" v-if="!isLoggedIn">
                <router-link to="/login"
                  >Join to get points!</router-link
                >
              </div>
            </div>
            <div class="pic">
              <img src="../assets/images/banner-right01.png" />
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div>

  <div class="filter-wrap">
    <div class="filters-btn" @click="toggleDropdown">
      <el-icon style="color: #090970; font-size: 25px; margin-right: 6px"
        ><Filter
      /></el-icon>
      Filters
      <font-awesome-icon
        v-if="!isDropdownOpen"
        icon="chevron-down"
        class="chevron"
        style="font-size: 18px; margin-left: 9px"
      />
      <font-awesome-icon
        v-else
        icon="chevron-up"
        class="chevron"
        style="font-size: 18px; margin-left: 9px"
      />
    </div>
    <div class="filters-select">
      <div class="fs-clear">
        <label><a @click="clearFilters">Clear Filters</a></label>
      </div>
    </div>
    <div class="fs-view-history" @mouseenter="showHistory" @mouseleave="hideHistory">
      <label><a>View History</a></label>
      <div class="history-dropdown" v-if="isHistoryOpen">
        HISTORY
        <ul>
          <li v-for="(article, index) in readArticles" :key="article.id">
            <router-link :to="{ path: '/article', query: { id: article.id, name: article.name, source: article.source, topic: article.topic, category: article.category, readingTimes: article.readingTimes, link: article.link, overview: article.overview } }">
              {{ article.name }}
            </router-link>
            <hr v-if="index < readArticles.length - 1" />
          </li>
        </ul>
      </div>      
    </div>
    <div class="filter-search" style="width: 50%">
      <el-input
        v-model="search"
        placeholder="Please input key words in title..."
        class="input-with-select"
        size="large"
        prefix-icon="Search"
      >
      </el-input>
    </div>
    <div class="filters-con">
      <div class="filters-plus">
        <a @click="addArticles">
          <el-icon style="font-size: 40px; margin-right: 14px; color: #090970"
            ><CirclePlus
          /></el-icon>
        </a>
        <div class="filters-tips">Add Article</div>
      </div>

      <div class="filters-sort">
        <div class="fsb-text">
          Sort by
          <i class="iconfont">
            <font-awesome-icon
              icon="chevron-down"
              style="color: #090970; font-size: 18px; margin-left: 9px"
            />
          </i>
        </div>
        <div class="fsb-box">
          <div class="fsb-link">
            <a @click="reloadArticles">Random</a>
            <a @click="loadArticlesDESC"
              >Reading Time<font-awesome-icon
                icon="arrow-down"
                style="font-size: 10px; margin-left: 5px"
            /></a>
            <a @click="loadArticlesASC"
              >Reading Time<font-awesome-icon
                icon="arrow-up"
                style="font-size: 10px; margin-left: 5px"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="filter-list" v-show="isDropdownOpen">
    <div class="fl-item">
      <label>Topics</label>
      <div class="fli-link" id="filter-topics">
        <a
          :class="{ cur: topicIsSelected[0] }"
          v-on:click="topicIsSelected[0] = !topicIsSelected[0]"
          ><component :is="icons['Technology & Science']" /><span
            >Technology & Science</span
          ></a
        >
        <a
          :class="{ cur: topicIsSelected[1] }"
          v-on:click="topicIsSelected[1] = !topicIsSelected[1]"
          ><component :is="icons['Education']" /><span>Education</span></a
        >
        <a
          :class="{ cur: topicIsSelected[2] }"
          v-on:click="topicIsSelected[2] = !topicIsSelected[2]"
          ><component :is="icons['COVID-19']" /><span>COVID-19</span></a
        >
        <a
          :class="{ cur: topicIsSelected[3] }"
          v-on:click="topicIsSelected[3] = !topicIsSelected[3]"
          ><component :is="icons['Health']" /><span>Health</span></a
        >
        <a
          :class="{ cur: topicIsSelected[4] }"
          v-on:click="topicIsSelected[4] = !topicIsSelected[4]"
          ><component :is="icons['Society & Culture']" /><span
            >Society & Culture</span
          ></a
        >
        <a
          :class="{ cur: topicIsSelected[5] }"
          v-on:click="topicIsSelected[5] = !topicIsSelected[5]"
          ><component :is="icons['Environment']" /><span>Environment</span></a
        >
        <a
          :class="{ cur: topicIsSelected[6] }"
          v-on:click="topicIsSelected[6] = !topicIsSelected[6]"
          ><component :is="icons['Business & Finance']" /><span
            >Business & Finance</span
          ></a
        >
        <a
          :class="{ cur: topicIsSelected[7] }"
          v-on:click="topicIsSelected[7] = !topicIsSelected[7]"
          ><component :is="icons['Entertainment & Arts']" /><span
            >Entertainment & Arts</span
          ></a
        >
        <a
          :class="{ cur: topicIsSelected[8] }"
          v-on:click="topicIsSelected[8] = !topicIsSelected[8]"
          ><component :is="icons['Politics']" /><span>Politics</span></a
        >
      </div>
    </div>

    <div class="fl-item">
      <label>Category</label>
      <div class="fli-link" id="filter-category">
        <a
          :class="{ cur: categoryIsSelected[0] }"
          v-on:click="categoryIsSelected[0] = !categoryIsSelected[0]"
          ><component :is="icons['News']" /><span>News</span></a
        >
        <a
          :class="{ cur: categoryIsSelected[1] }"
          v-on:click="categoryIsSelected[1] = !categoryIsSelected[1]"
          ><component :is="icons['Advice']" /><span>Advice</span></a
        >
        <a
          :class="{ cur: categoryIsSelected[2] }"
          v-on:click="categoryIsSelected[2] = !categoryIsSelected[2]"
          ><component :is="icons['Analysis']" /><span>Analysis</span></a
        >
        <a
          :class="{ cur: categoryIsSelected[3] }"
          v-on:click="categoryIsSelected[3] = !categoryIsSelected[3]"
          ><component :is="icons['Interview']" /><span>Interview</span></a
        >
        <a
          :class="{ cur: categoryIsSelected[4] }"
          v-on:click="categoryIsSelected[4] = !categoryIsSelected[4]"
          ><component :is="icons['Discussion']" /><span>Discussion</span></a
        >
        <a
          :class="{ cur: categoryIsSelected[5] }"
          v-on:click="categoryIsSelected[5] = !categoryIsSelected[5]"
          ><component :is="icons['Chronicle']" /><span>Chronicle</span></a
        >
        <a
          :class="{ cur: categoryIsSelected[6] }"
          v-on:click="categoryIsSelected[6] = !categoryIsSelected[6]"
          ><component :is="icons['Video']" /><span>Video</span></a
        >
      </div>
    </div>

    <div class="fl-item">
      <label>Reading Time</label>
      <div class="fli-time">
        <div class="fli-slider">
          <span class="flis-left">0</span>
          <el-slider
            v-model="value_slider"
            :min="0"
            :max="60"
            range
            style="line-height: 1px"
          />
          <span class="flis-right">60</span>
        </div>
      </div>
    </div>

    <br />

    <div class="fl-item">
      <label>Source</label>
      <div class="fli-link" id="filter-source">
        <div
          v-for="(source, index) in sources"
          v-bind:key="source"
          v-on:click="sourceIsSelected[index] = !sourceIsSelected[index]"
        >
          <a :class="{ cur: sourceIsSelected[index] }">
            <span>
              {{ source }}
            </span>
          </a>
        </div>
      </div>
    </div>

    <div class="fl-item">
      <label><a @click="applyFilters">Apply Filters</a></label>
    </div>
  </div>

  <div class="articles-list" v-loading="loading">
    <ul v-if="search === ''" class="wrapper" id="mainContents">
      <li v-for="article in articles" v-bind:key="article.id" :class="{'bg-video': article.category === 'Video'}">
        <a :href="article.link" target="_blank" @click.prevent="openArticle(article)">
          <div class="al-text">
            <div class="al-title">{{ article.name }}</div>
            <div class="al-from">{{ "from: " + article.source }}</div>
            <el-scrollbar
              height="50px"
              style="font-size: 13px; color: #3f3d56; line-height: 1.2"
              >{{ article.overview }}</el-scrollbar
            >
          </div>
          <div class="al-icon" :class="'bg-' + article.topic.split(' ')[0]">
            <div class="ali-item">
              <span class="icon-'.$topic.'"
                ><component :is="icons[article.topic]" />{{
                  article.topic
                }}</span
              >
            </div>
            <div class="ali-item">
              <span class="icon-'.$category.'"
                ><component :is="icons[article.category]" />{{
                  article.category
                }}</span
              >
              <span class="icon-time"
                ><component style="margin-right: 3px" :is="icons['Time']" />{{
                  article.readingTimes + " mins"
                }}</span
              >
            </div>
          </div>
        </a>
      </li>
    </ul>
    <ul v-else class="wrapper" id="mainContents">
      <li v-for="article in searchBy(articles, search)" v-bind:key="article.id" :class="{'bg-video': article.category === 'Video'}">
        <a :href="article.link" target="_blank" @click.prevent="openArticle(article)">
          <div class="al-text">
            <div class="al-title">{{ article.name }}</div>
            <div class="al-from">{{ "from: " + article.source }}</div>
            <el-scrollbar
              height="50px"
              style="font-size: 13px; color: #3f3d56; line-height: 1.2"
              >{{ article.overview }}</el-scrollbar
            >
          </div>
          <div class="al-icon" :class="'bg-' + article.topic.split(' ')[0]">
            <div class="ali-item">
              <span class="icon-'.$topic.'"
                ><component :is="icons[article.topic]" />{{
                  article.topic
                }}</span
              >
            </div>
            <div class="ali-item">
              <span class="icon-'.$category.'"
                ><component :is="icons[article.category]" />{{
                  article.category
                }}</span
              >
              <span class="icon-time"
                ><component style="margin-right: 3px" :is="icons['Time']" />{{
                  article.readingTimes + " mins"
                }}</span
              >
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { get, post } from "@/api";
import { ref, shallowRef } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import { useRouter } from "vue-router";
import Tech from "@/components/icons/Tech.vue";
import Edu from "@/components/icons/Edu.vue";
import Covid from "@/components/icons/Covid.vue";
import Health from "@/components/icons/Health.vue";
import Society from "@/components/icons/Society.vue";
import Env from "@/components/icons/Env.vue";
import Busi from "@/components/icons/Busi.vue";
import Ent from "@/components/icons/Ent.vue";
import Poli from "@/components/icons/Poli.vue";
import News from "@/components/icons/News.vue";
import Advice from "@/components/icons/Advice.vue";
import Analy from "@/components/icons/Analy.vue";
import Interview from "@/components/icons/Interview.vue";
import Discuss from "@/components/icons/Discuss.vue";
import Chron from "@/components/icons/Chron.vue";
import Video from "@/components/icons/Video.vue"
import { TimeIcon } from "@layui/icons-vue";

export default {
  name: "Home",
  setup() {
    const icons = shallowRef({
      "Technology & Science": Tech,
      Education: Edu,
      "COVID-19": Covid,
      Health: Health,
      "Society & Culture": Society,
      Environment: Env,
      "Business & Finance": Busi,
      "Entertainment & Arts": Ent,
      Politics: Poli,
      News: News,
      Advice: Advice,
      Analysis: Analy,
      Interview: Interview,
      Discussion: Discuss,
      Chronicle: Chron,
      Video:Video,
      Time: TimeIcon,
    });

    const loading = ref(true);
    const value_slider = ref([0, 60]);
    const isDropdownOpen = ref(false);
    const isHistoryOpen = ref(false);
    const sources = ref("");
    const articles = ref("");
    const uid = ref(-1);
    const uname = ref("");
    const isLoggedIn = ref(false);
    const router = useRouter();
    const search = ref("");
    const readArticles = ref([]);

    const topicIsSelected = ref(new Array(9));
    const categoryIsSelected = ref(new Array(7));
    const sourceIsSelected = ref(new Array(sources.value.length));

    function searchBy(articles, input) {
      return articles.filter((article) => {
        return article.name.toLowerCase().match(input.toLowerCase());
      });
    }

    function showHistory() {
      console.log("Show history triggered");
      if (isLoggedIn.value) {
        isHistoryOpen.value = true;
        loadReadArticles();
      }
    }

    function hideHistory() {
      console.log("Hide history triggered");
      isHistoryOpen.value = false;
    }

    async function loadReadArticles() {
  try {
    const response = await post("/activity/browse-history", { userId: uid.value });
    console.log("Load read articles response:", response);
    if (response && response.data) {
      readArticles.value = response.data.filter(article => article && article.id).map(article => {
        try {
          return {
            id: article.id,
            name: article.name || 'No Name',
            source: article.source || 'Unknown Source',
            topic: article.topic || 'No Topic',
            category: article.category || 'No Category',
            readingTimes: article.readingTimes || 0,
            link: article.link || 'No Link',
            overview: article.overview || 'No Overview'
          };
        } catch (error) {
          console.error('Error mapping article:', error);
          return null; 
        }
      }).filter(article => article != null);
    } else {
      console.log("No data received");
    }
  } catch (error) {
    console.error("Failed to load read articles:", error);
  }
  console.log("readArticles:", readArticles.value);
}



    loadSources();

    function loadSources() {
      get("/article/source").then((res) => {
        sources.value = res.sources;
      });
    }



    function loadArticles() {
      loading.value = true;
      post("/article/all",{userId: uid.value}).then((res) => {
        loading.value = false;
        articles.value = res.articles;
      });
    }

    function reloadArticles() {
      loading.value = true;
      post("/article/refresh",{userId: uid.value}).then((res) => {
        if (res.res ===1){
          post("/article/all",{userId: uid.value}).then((res) => {
          loading.value = false;
          articles.value = res.articles;
      });
        } else{
          ElMessage({
                message: "Refresh Failed",
                type: "error",
              });
        }
      });
     
    }

    function loadArticlesDESC() {
      loading.value = true;
      get("/article/TimeDESC").then((res) => {
        loading.value = false;
        articles.value = res.articles;
      });
    }

    function loadArticlesASC() {
      loading.value = true;
      get("/article/TimeASC").then((res) => {
        loading.value = false;
        articles.value = res.articles;
      });
    }

    function browsing() {
      post("/activity/browsing", { uid: uid.value, uname: uname.value });
    }

    function addArticles() {
      if (!isLoggedIn.value) {
        router.push("/login");
        return;
      }

      if (sessionStorage.getItem("newArticle") != null) {
        sessionStorage.removeItem("newArticle");
      }
      if (sessionStorage.getItem("newQuestion") != null) {
        sessionStorage.removeItem("newQuestion");
      }
      router.push("/article-add");
    }

    load();
    function load() {
      console.log("Home token", sessionStorage.getItem("token"));
      if (sessionStorage.getItem("token")) {
        post(`/user/logged`).then((res) => {
          if (res.res == 1) {
            console.log("home load", res.user, res.token);
            uid.value = res.user.uid;
            uname.value = res.user.uname;
            isLoggedIn.value = true;
            console.log(uid.value);
            console.log(uname.value);
          } else {
            sessionStorage.removeItem("token");
          }
          browsing();
          loadArticles();
        });
      } else {
        browsing();
        loadArticles();
      }
    }

    function clearFilters() {
      location.reload();
    }

    function applyFilters() {
      post("/article/all",{userId: uid.value}).then((res) => {
        articles.value = [];
        const categorySelected = [];
        const sourceSelected = [];
        const topicSelected = [];

        const topicSelectedIndex = topicIsSelected.value.reduce(
          (indexes, element, index) => {
            if (element === true) {
              indexes.push(index);
            }
            return indexes;
          },
          []
        );
        const topic = [
          "Technology & Science",
          "Education",
          "COVID-19",
          "Health",
          "Society & Culture",
          "Environment",
          "Business & Finance",
          "Entertainment & Arts",
          "Politics",
        ];
        for (let i = 0, len = topicSelectedIndex.length; i < len; i++) {
          topicSelected.push(topic[topicSelectedIndex[i]]);
        }
        console.log(topicSelected);

        const categorySelectedIndex = categoryIsSelected.value.reduce(
          (indexes, element, index) => {
            if (element === true) {
              indexes.push(index);
            }
            return indexes;
          },
          []
        );
        const category = [
          "News",
          "Advice",
          "Analysis",
          "Interview",
          "Discussion",
          "Chronicle",
          "Video",
        ];
        for (let i = 0, len = categorySelectedIndex.length; i < len; i++) {
          categorySelected.push(category[categorySelectedIndex[i]]);
        }
        console.log(categorySelected);

        const sourceSelectedIndex = sourceIsSelected.value.reduce(
          (indexes, element, index) => {
            if (element === true) {
              indexes.push(index);
            }
            return indexes;
          },
          []
        );
        for (let i = 0, len = sourceSelectedIndex.length; i < len; i++) {
          sourceSelected.push(sources.value[sourceSelectedIndex[i]]);
        }
        console.log(sourceSelected);

        for (let i = 0, len = res.articles.length; i < len; i++) {
          if (
            (topicSelected.indexOf(res.articles[i].topic) != -1 ||
              topicSelected.length == 0) &&
            (categorySelected.indexOf(res.articles[i].category) != -1 ||
              categorySelected.length == 0) &&
            value_slider.value[0] <= res.articles[i].readingTimes &&
            res.articles[i].readingTimes <= value_slider.value[1] &&
            (sourceSelected.indexOf(res.articles[i].source) != -1 ||
              sourceSelected.length == 0)
          ) {
            console.log(res.articles[i]);
            articles.value.push(res.articles[i]);
          }
        }
        console.log(articles.value.length);
      });
    }

    function openArticle(article) {
      // window.open(article.link, "_blank");
      sessionStorage.setItem("articleDetail", JSON.stringify({
        id: article.id,
        name: article.name,
        source: article.source,
        topic: article.topic,
        category: article.category,
        readingTimes: article.readingTimes,
        overview: article.overview,
        link: article.link,
      }));
      router.push({
        path: "/article",
        // query: {
        //   id: article.id,
        //   name: article.name,
        //   source: article.source,
        //   topic: article.topic,
        //   category: article.category,
        //   readingTimes: article.readingTimes,
        //   link: article.link,
        //   overview: article.overview,
        // },
      });
     
    }

    return {
      loading,
      value_slider,
      isDropdownOpen,
      isHistoryOpen,
      articles,
      sources,
      applyFilters,
      sourceIsSelected,
      topicIsSelected,
      categoryIsSelected,
      loadArticles,
      icons,
      loadArticlesDESC,
      loadArticlesASC,
      addArticles,
      reloadArticles,
      search,
      searchBy,
      clearFilters,
      readArticles,
      showHistory,
      hideHistory,
      openArticle,
      isLoggedIn,
    };
  },
  methods: {
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
      console.log(this.isDropdownOpen);
    },
  },
};
</script>

<style scoped>
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.wrapper {
  width: 1240px;
  margin: 0 auto;
}
/*banner*/
.topbanner-container.home {
  width: 100%;
  height: 100%;
  margin: 0 auto 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  z-index: 1;
  /*border: 1px solid;*/
}

.banner {
  display: block;
  margin: 0 auto;
  width: 1260px;
  height: 290px;
  overflow: hidden;
  border-radius: 0 0 80px 80px;
  /*border: 1px solid blue;*/
}
.banner01 {
  display: flex;
  width: 100%;
  /*height: 100%;*/
  align-items: center;
  justify-content: space-between;
  padding: 10px 135px 50px 135px;
  background-color: #ebedf2;
  /*border: 1px solid red;*/
}
.banner01 .text {
  width: 690px;
}
.banner01 .text .hi {
  font-size: 20px;
  color: #14279b;
}
.banner01 .text .title {
  margin-top: 8px;
  font-size: 28px;
  line-height: 1.5;
  color: #14279b;
  font-family: "Poppins-SemiBold";
}
.banner01 .text .desc {
  margin-top: 12px;
  font-size: 14px;
  color: #082032;
  line-height: 1.5;
}
.banner01 .btn {
  display: block;
  width: 220px;
  height: 45px;
  margin-top: 22px;
}
.banner01 .btn a {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-size: 15px;
  font-weight: bold;
  color: #ffffff;
  border-radius: 23px;
  background-color: #3b5df5;
}
.banner01 .btn a:hover {
  background-color: #1e42e4;
}

.topbanner-container.home {
  width: 100%;
  height: 100%;
  margin: 0 auto 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  z-index: 1;
}
.topbanner-container .swiper-pagination {
  align-items: center;
  bottom: 12px;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 100%;
}
.topbanner-container .swiper-pagination .swiper-pagination-bullet {
  background: #cacaca;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: 0 4px;
  position: relative;
  transition: all 0.2s ease-in;
  opacity: 0.8 !important;
}
.topbanner-container .swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}
.topbanner-container .swiper-pagination .swiper-pagination-bullet-active {
  width: 8px;
  height: 8px;
  opacity: 1 !important;
  box-sizing: unset;
  background: #608af5;
}

/*filter*/
.filter-wrap {
  display: flex;
  width: 1240px;
  margin: 30px auto 35px auto;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.filters-btn {
  display: flex;
  width: 133px;
  align-items: center;
  height: 36px;
  color: #111880;
  font-size: 16px;
  font-family: "Poppins-Medium";
  padding: 0 32px 0 18px;
  border-radius: 18px;
  cursor: pointer;
  background-size: 18px 17px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.filters-btn.cur {
  background-size: 18px 17px;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
.filters-btn:hover {
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}

.filters-btn.cur {
  background-size: 18px 17px;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
.chevron.cur {
  transform: scaleY(-1);
}

.filters-select {
  display: flex;
  /*position: absolute;*/
  top: 0;
  /*margin-left: 25px;*/
  width: auto;
  height: auto;
  min-height: 36px;
}
.filters-select ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.filters-select li {
  display: flex;
  align-items: center;
  padding: 0 26px 0 10px;
  height: 32px;
  font-size: 12px;
  color: #4a88f8;
  background-color: #dbe7fe;
  border-radius: 16px;
  margin-right: 10px;
  position: relative;
  margin-bottom: 10px;
}
.filters-select li .fs-close {
  position: absolute;
  right: 8px;
  top: 0;
  height: 32px;
  width: 15px;
  cursor: pointer;
  /* background: url(../assets/images/icon-s-close.png) no-repeat center center; */
  background-size: 15px 15px;
}
.filters-select .fs-clear {
  position: relative;
  text-align: left;
  width: 110px;
  line-height: 36px;
  padding-top: 2px;
}
.filters-select .fs-clear a {
  color: #969696;
}
.filters-select .fs-clear a:hover {
  color: #666666;
}

.filters-con {
  display: flex;
  align-items: center;
  align-content: center;
}
/*add articles*/
.filters-plus {
  width: 36px;
  height: 36px;
  margin-right: 14px;
  cursor: pointer;
  position: relative;
}
.filters-plus .filters-tips {
  display: none;
}
.filters-plus:hover .filters-tips {
  display: block;
}


.filters-tips {
  position: absolute;
  left: 50%;
  top: -38px;
  width: auto;
  height: auto;
  padding: 8px 10px;
  font-size: 12px;
  color: #111880;
  border-radius: 5px;
  background-color: #ffffff;
  z-index: 1000;
  white-space: nowrap;
  transform: translate(-50%, 0);
  box-shadow: 0px 2px 10px 0px rgb(0 0 0 / 20%);
}
.filters-tips:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #ffffff;
  position: absolute;
  bottom: -5px;
  left: 50%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 999;
  opacity: 1;
  transform: translate(-50%, 0);
  box-shadow: 1px 2px 13px rgb(231 231 231 / 80%);
}
/*sort*/
.filters-sort {
  display: block;
  /* width: 118px; */
  max-width: 150px;
  height: 36px;
  margin-right: 10px;
  position: relative;
  z-index: 10;
}
.filters-sort .fsb-text {
  display: flex;
  align-items: center;
  width: 100%;
  /* max-width: 50px; */
  height: 36px;
  cursor: pointer;
  color: #111880;
  font-size: 15px;
  font-family: "Poppins-Medium";
  padding: 0 32px 0 18px;
  border-radius: 18px;
  /* background: #FFFFFF url(../assets/images/icon-down.png) no-repeat 85px center; */
  /* background-size: 18px 17px; */
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.filters-sort:hover .fsb-text {
  /* background: #FFFFFF url(../assets/images/icon-up.png) no-repeat 85px center; */
  background-size: 18px 17px;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
.filters-sort:hover .fsb-box {
  display: block;
}
.filters-sort .fsb-box {
  position: absolute;
  display: none;
  top: 36px;
  left: 0;
  width: 100%;
  padding-top: 6px;
  z-index: 100;
}
.filters-sort .fsb-link {
  width: 100%;
  height: auto;
  padding: 8px 13px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  z-index: 100;
}
.filters-sort .fsb-link a {
  display: block;
  width: 100%;
  line-height: 22px;
  font-size: 12px;
  color: #111880;
  position: relative;
}
.filters-sort .fsb-link a:hover {
  color: #5473ff;
}
.filters-sort .fsb-link a.fsb-line {
  border-top: 1px solid #eaeaea;
  margin-top: 5px;
  padding-top: 5px;
}
.filters-sort .fsb-link a img {
  position: absolute;
  right: -3px;
  top: 6px;
  width: 10px;
  height: 10px;
}
/*grid*/
.filter-view {
  display: flex;
}
.filter-view li {
  width: 40px;
  height: 40px;
  border-radius: 15px;
  margin-left: 10px;
  background-color: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  position: relative;
}
.filter-view li a {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  align-content: center;
}
.filter-view li a img {
  width: 30px;
  height: 30px;
}
.filter-view li.cur {
  background-color: #edf4ff;
  border: 3px solid #ccddfd;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0);
}
.filter-view li:hover {
  background-color: #edf4ff;
  border: 3px solid #ccddfd;
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
.filter-view li .filters-tips {
  display: none;
  top: -41px;
}
.filter-view li:hover .filters-tips {
  display: block;
}

/*filter-list*/
.filterShow {
  display: block;
}
.filter-list {
  /* display: none; */
  margin: 0 auto 40px auto;
  width: 1240px;
  height: auto;
}
.filter-list .fl-item {
  display: flex;
  height: auto;
  align-items: flex-start;
  align-content: flex-start;
  padding: 5px 0;
}
.filter-list .fl-item label {
  font-size: 14px;
  line-height: 25px;
  padding-left: 20px;
  width: 12%;
  color: #111880;
  font-family: "Poppins-Medium";
}
.filter-list .fl-item .fli-link {
  display: flex;
  width: 88%;
  flex-wrap: wrap;
  align-content: flex-start;
}
.filter-list .fl-item .fli-link a {
  display: flex;
  align-items: center;
  line-height: 25px;
  height: 25px;
  padding: 0 10px;
  font-size: 12px;
  color: #111880;
  margin: 0 20px 10px 0;
  border-radius: 13px;
  background-color: #ffffff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}
.filter-list .fl-item .fli-link a:hover {
  box-shadow: 0px 2px 10px 0px rgba(59, 93, 245, 0.5);
}
.filter-list .fl-item .fli-link a img {
  max-width: 17px;
  max-height: 17px;
  margin-right: 5px;
}
.filter-list .fl-item .fli-link a.cur {
  border: 2px solid #cddefe;
  height: 25px;
  padding: 0 8px;
  color: #7f86c0;
  line-height: 21px;
  background-color: #edf4ff;
}
.filter-list .fl-item .fli-link a.cur img {
  opacity: 0.5;
}
.filter-list .fl-item .fli-time {
  width: 88%;
  height: 25px;
}
.filter-list .fl-item .fli-time .fli-slider {
  margin: 10px 0;
  width: 250px;
  height: 5px;
  padding: 0 25px 0 20px;
  position: relative;
}
.filter-list .fl-item .fli-time .flis-left {
  position: absolute;
  left: 0;
  top: -3px;
  width: 20px;
  text-align: center;
  font-size: 12px;
  color: #111880;
}
.filter-list .fl-item .fli-time .flis-right {
  position: absolute;
  right: 0;
  top: 0;
  top: -3px;
  width: 20px;
  text-align: center;
  font-size: 12px;
  color: #111880;
}
.filter-list .fl-item .fli-time .layui-slider {
}
.filter-list .fl-item .fli-time .layui-slider .layui-slider-bar {
  background: #4a88f8 !important;
}
.filter-list .fl-item .fli-time .layui-slider .layui-slider-wrap-btn {
  border: 2px solid #111880 !important;
}

.fl-item label a {
  display: inline-block;
  font-size: 14px; /* Increase font size */
  font-weight: bold; /* Make the text bold */
  color: #ffffff; /* Change the text color */
  background-color: #111880; /* Add a background color */
  padding: 10px 20px; /* Add padding for more space around the text */
  border-radius: 8px; /* Round the corners */
  text-align: center; /* Center the text */
  cursor: pointer; /* Add a pointer cursor on hover */
  transition: background-color 0.3s, transform 0.2s; /* Add transition for hover effect */
}

.fl-item label a:hover {
  background-color: #7f86c0; /* Darker background on hover */
  transform: scale(1.05); /* Slightly increase size on hover */
  text-decoration: none; /* Remove underline */
}

.articles-list {
  display: block;
  width: 100%;
  margin-bottom: 30px;
}
.articles-list ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.articles-list li {
  width: 290px;
  height: 280px;
  background-color: #ffffff;
  border-radius: 30px;
  margin-bottom: 25px;
  margin-right: 26px;
  overflow: hidden;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
}
.articles-list li.read,
.articles-list.view-list li.read {
  opacity: 0.4;
}
.articles-list li.read .al-text .al-title {
  color: #000000;
}
.articles-list li:hover {
  box-shadow: 0px 2px 20px 0px rgba(59, 93, 245, 0.5);
}
.articles-list li:nth-child(4n) {
  margin-right: 0;
}
.articles-list.view-list li {
  width: 100%;
  height: 145px;
  margin-right: 0;
}
.articles-list.view-list .al-text {
  padding: 12px 3% 12px 3%;
  height: 95px;
}
.articles-list.view-list .al-icon {
  height: 50px;
  font-size: 12px;
  padding: 4px 3%;
}
.articles-list.view-list .al-icon .ali-item {
  margin-bottom: 2px;
}
.articles-list.view-list .al-text .al-from {
  margin: 3px 0 5px 0;
}
.articles-list .al-text {
  display: block;
  padding: 20px 20px 20px 20px;
  height: 215px;
}
.articles-list .al-text .al-title {
  font-size: 18px;
  line-height: 1.6;
  font-family: "Poppins-ExtraBold";
}
.articles-list .al-text .al-from {
  font-size: 16px;
  font-style: italic;
  color: #969696;
  margin: 12px 0;
  font-weight: bold;
}
.articles-list .al-text el-scrollbar {
  font-size: 12px;
  color: #3f3d56;
}
.articles-list .al-icon {
  display: block;
  height: 65px;
  color: #ffffff;
  font-size: 12px;
  padding: 10px 20px;
  background-color: #726afa;
}
.articles-list .al-icon.bg-Society {
  background-color: #726afa;
}
.articles-list .al-icon.bg-Technology {
  background-color: #5473ff;
}
.articles-list .al-icon.bg-COVID-19 {
  background-color: #e06546;
}
.articles-list .al-icon.bg-Business {
  background-color: #a17f68;
}
.articles-list .al-icon.bg-Environment {
  background-color: #98ca9f;
}
.articles-list .al-icon.bg-Education {
  background-color: #7fbedd;
}
.articles-list .al-icon .ali-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}
.articles-list .al-icon .ali-item span {
  display: flex;
  height: 20px;
  line-height: 20px;
  align-items: center;
}
.articles-list .al-icon .ali-item span img {
  margin-right: 8px;
}

:deep(.el-input__wrapper) {
  border-radius: 95px;
}

:deep(.el-input-group__append) {
  border-radius: 95px;
}

.fs-view-history {
  position: relative; 
}

.history-dropdown {
  position: absolute;
  top: 100%;
  left: -150px;
  background-color: white;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 100;
  width: 400px;
  border-radius: 5px;
  padding: 10px;
}

.history-dropdown ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.history-dropdown li {
  padding: 5px 0;
}

.history-dropdown li a {
  color: #111880;
  text-decoration: none;
}

.history-dropdown li a:hover {
  text-decoration: underline;
}

.articles-list li.bg-video {
  background-color: #f8f4e3; 
}

</style>
