<template>
    <el-icon style="font-size: 13px; margin-right: 5px;"><VideoCameraFilled /></el-icon>
  </template>
  
  <script>
  import { VideoCameraFilled } from '@element-plus/icons-vue';
  
  export default {
    name: "Video",
    components: {
      VideoCameraFilled
    }
  }
  </script>
  
  <style scoped>
  
  </style>
  